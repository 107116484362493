import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useState } from "react";
import * as Yup from "yup";

import Button from "../../../../../atoms/button/Button";
import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import { useZipCode } from "../../../../../core/domain/hooks/useZipCode";
import PageStepForm from "../../../../../organisms/page-step-form/PageStepForm";
import { Result } from "../../../../../organisms/result/domain/result";
import {
  useResult,
  useUpdateResult,
} from "../../../../../organisms/result/use-cases/result-use-cases";
import { Events } from "../../../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import { getZipCodeLength } from "../../../../../utils/locale-configuration-utils";
import * as styles from "./Address.module.scss";

const addressSVG: string =
  require("../../../../../images/pages/results/address/worldGlobe.svg").default;

interface AddressFormValues {
  pet_parent_address: string;
  pet_parent_city: string;
  pet_parent_zip: string;
}

const Address = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const updateResult = useUpdateResult();
  const [validateOnMount, setValidateOnMount] = useState(true);

  const zipCodeValidation = useZipCode({
    country: result.country,
    initialZipCode: result?.pet_parent_zip || "",
  });

  useTrackPageViewed(Events.ADDRESS_VIEWED_BROWSER);

  const accessFormData = {
    initialValues: {
      pet_parent_address: result?.pet_parent_address || "",
      pet_parent_city: result?.pet_parent_city || "",
      pet_parent_zip: zipCodeValidation.initialValues.zipCode,
    },
    validationSchema: Yup.object({
      pet_parent_address: Yup.string().trim().required(t("common.validation.required")),
      pet_parent_city: Yup.string().trim().required(t("common.validation.required")),
      pet_parent_zip: zipCodeValidation.validationSchema.fields.zipCode,
    }),
    validateOnMount,
    handleSubmit: (
      values: AddressFormValues,
      { setSubmitting }: FormikHelpers<AddressFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      setSubmitting(true);
      updateResult(values)
        .then(() => {
          const nextUrl = !result.isCardAlreadyIssued
            ? `/results/${result?.uuid}/shipping/`
            : `/results/${result?.uuid}/access/`;

          void navigate(nextUrl);
        })
        .catch((err: Error) => {
          handleUpdateResultErrors(err, "address");
          alert(t("landing.error_message"));
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<AddressFormValues>) => {
      const { isSubmitting, isValid, submitCount } = props;
      const handleClick = () => {
        setValidateOnMount(submitCount > 0);
      };

      return (
        <Form className={styles.addressForm}>
          <TextFormControl
            name="pet_parent_address"
            label={t("pet_parent.address.address.field.title")}
            autoComplete="street-address"
            adoptionClassName={styles.address}
          />
          <TextFormControl
            name="pet_parent_zip"
            label={t("pet_parent.address.zip.field.title")}
            autoComplete="postal-code"
            adoptionClassName={styles.parentZip}
            maxLength={getZipCodeLength(result.country).max}
          />
          <TextFormControl
            name="pet_parent_city"
            label={t("pet_parent.address.city.field.title")}
            autoComplete="address-level2"
            adoptionClassName={styles.city}
          />
          <Button
            type="submit"
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            onClick={handleClick}
          >
            {t("pet_parent.cta")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <PageStepForm
            title={t("pg_address.title", {
              petParentName: result.pet_parent_first_name,
              petName: result.pet_name,
            })}
            image={addressSVG}
            formData={accessFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default Address;
